import './style';
import {useState} from 'preact/hooks';
import {SfclHelper} from "./components/sfcl_helper";
import {Login} from "./components/login";

export default function App() {
    const [loggedInUser, setLoggedInUser] = useState(undefined);

    const onLogin = (loggedInUser) => {
        setLoggedInUser(loggedInUser);
    };
    return (
        <div>
            <h1>SFCL Helfer</h1>
            {loggedInUser ?
                <SfclHelper loginInfo={loggedInUser}/>
                :
                <Login onLogin={onLogin}/>
            }
        </div>
    );
}
