import {useState} from "preact/hooks";
import {apiService} from "../api.service";
import {LoadingIndicator} from "./loading_indicator";

export const Login = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [status, setStatus] = useState({
        class: "info",
        message: "Mit Vereinsflieger-Login einloggen:",
        buttonDisabled: false
    });

    const login = (e) => {
        console.log(`logging in with username ${username}`);
        setLoading(true)
        setStatus({message: "Login wird durchgeführt...", class: "info", buttonDisabled: true})

        apiService.login(username, password).then(loginResult => {
            if (loginResult.success) {
                setStatus({class: "info", message: "Login erfolgreich", buttonDisabled: false})
                props.onLogin({username, accessToken: loginResult.accessToken})
            } else {
                setStatus({
                    class: "error",
                    message: "Login fehlgeschlagen, bitte Daten überprüfen!",
                    buttonDisabled: false
                })
            }
            setLoading(false)
        }).catch(error => {
            console.error(error)
            setLoading(false)
            setStatus({class: 'error', message: `Ein Fehler ist aufgetreten: ${error}`, buttonDisabled: false})
        });
        e.preventDefault()
    };
    const onUserInput = (e) => setUsername(e.target.value);
    const onPassInput = (e) => setPassword(e.target.value);

    return (
        <form onsubmit={login}>
            {isLoading?
                <LoadingIndicator message={status.message} />:
            <div class={`status-${status.class}`}>{status.message}</div>}
            <div>
                <label>Username</label>
                <input type="text" name="Username" placeholder="Username" onInput={onUserInput} value={username}/>
            </div>
            <div>
                <label>Passwort</label>
                <input type="Password" name="Password" placeholder="Passwort" onInput={onPassInput} value={password}/>
            </div>
            <div>
                <button type="submit" disabled={status.buttonDisabled}>Login</button>
            </div>
        </form>
    );
}