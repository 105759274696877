import {SfclRule} from "./sflc_rule";
import {LoadingIndicator} from "./loading_indicator";

export function SfclRuleList({ruleResults}) {
    return (
        <div>
            {ruleResults.length < 1 ? <LoadingIndicator isLoading="true" message="Lade und analysiere Flüge" />
                :
                ruleResults.map(ruleResult => {
                    return (<SfclRule result={ruleResult} />)
                })
            }
        </div>
    );
}