import md5 from "crypto-js/md5";
import CryptoJS from "crypto-js";

const API_BASE_URL = API_SERVER;
const analysisTimeout = 75000;
export const apiService = {
    login: (username, password) => {
        return withTimeout(fetch(`${API_BASE_URL}accessToken`),1500)
            .then(response => response.text())
            .then(async accessToken => {
                const response = await fetch(`${API_BASE_URL}signin`, {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        accesstoken: accessToken,
                        username,
                        password: md5(password).toString(CryptoJS.enc.Hex) // vereinsflieger api uses md5 :(
                    })
                });
                return {success: response.status === 200, accessToken};
            });
    },
    loadFlights: (accessToken) => {
        return withTimeout(fetch(`${API_BASE_URL}flights`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accessToken,
                count: 200
            })
        }),analysisTimeout).then(response => response.json())
    },
    loadGliderStats: (accessToken) => {
        return withTimeout(fetch(`${API_BASE_URL}sfclStats`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accessToken,
            })
        }),analysisTimeout).then(response => response.json())
    },
    loadTmgStats: (accessToken) => {
        return withTimeout(fetch(`${API_BASE_URL}tmgStats`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accessToken,
            })
        }),analysisTimeout).then(response => response.json())
    }
}

function withTimeout(promise, timeout) {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
            reject(new Error('Time out'))
        }, timeout)

        promise.then(value => {
                clearTimeout(timer)
                resolve(value)
            }).catch(reason => {
                clearTimeout(timer)
                reject(reason)
            })
    })
}

