
export const SfclRule = (props) => {
    const ruleResult = props.result
    const ruleSatisfied = ruleResult.checkResult.actual >= ruleResult.checkResult.required;
    return (<div class={`sfcl-rule ${ruleSatisfied ? 'sfcl-rule--ok' : 'sfcl-rule--failed'}`}>
        <div class="sfcl-rule__description">{ruleResult.description}</div>
        <div
            class="sfcl-rule__result">{ruleResult.checkResult.actual} of {ruleResult.checkResult.required} {ruleResult.checkUnit}</div>
        <div class="sfcl-rule__valid-until">Gültig bis: {ruleResult.checkResult.validUntil ? new Date(ruleResult.checkResult.validUntil).toDateString():'-'}</div>
        <div class="sfcl-rule__legal-basis">Rechtsgrundlage: {ruleResult.legalBasis}</div>
        <div class="sfcl-rule__alternative">Alternativ: {ruleResult.alternative}</div>
    </div>);
}