import {useEffect, useState} from "preact/hooks";
import {SfclRuleList} from "./sfcle_rule_list";
import {apiService} from "../api.service";

export const SfclHelper = ({loginInfo}) => {
    const [state, setState] = useState("none")
    const [ruleResults, setRuleResults] = useState([])
    const [error, setError] = useState(undefined);

    function toggleState(target) {
        if (state === target) {
            setState("none")
        } else {
            console.log(`setting state ${target}`)
            setState(target)
        }
    }

    useEffect(() => {
        setRuleResults([])
        let result;
        switch (state) {
            case "sf":
                result = apiService.loadGliderStats(loginInfo.accessToken)
                break;
            case "tmg":
                result = apiService.loadTmgStats(loginInfo.accessToken)
                break
            default:
                result = Promise.resolve([])
        }
        result.then(result => {
            setError(undefined)
            setRuleResults(result)
        }).catch(error => {
            console.error(error)
            setError(error)
        });
    }, [loginInfo.accessToken, state])

    const showSf = () => toggleState("sf")
    const showTmg = () => toggleState("tmg")

    function getTitle() {
        switch (state) {
            case "sf": return "Segelflug"
            case "tmg": return "TMG"
            default: return ""
        }
    }

    return (
        <div>
            <div>Eingeloggt als {loginInfo.username}</div>
            <h2>SPL Regelcheck {getTitle()}</h2>
            <div class="tab-container">
                <button class={`${state === "sf" ? "active" : ""} tab-select`} onclick={showSf}><span>Segelflug</span></button>
                <button class={`${state === "tmg" ? "active" : ""} tab-select`} onclick={showTmg}><span>TMG</span></button>
            </div>
            <div>
                { error ? <div class="status-error">Ein Fehler ist aufgetreten: {error.message}</div>: '' }
                { state !== "none" && !error ?
                    <SfclRuleList ruleResults={ruleResults} /> : <div> Wähle Typ</div>
                }
            </div>
        </div>
    );
}